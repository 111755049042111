import React, { useMemo } from "react";
import { FaEye } from "react-icons/fa";
import { navigate } from "gatsby";
import Layout from "@components/layout";
import CircleLoading from "@components/circle-loading";
import queryMenu from "@graphql/menu";
import { formatGQLQuery } from "@utils";
import {
  popularArticles as popularArticlesQuery,
} from "@graphql/article";
import { allCategories } from "@graphql/category";
import Button from "@components/common/button";
import contactAndAddress from "@graphql/footer";

export default function Pihk(props) {
  const {
    menu,
    query: queryDefault,
    contact = {},
    address = {},
    additionalSearchData = null,
  } = props.serverData;

  const query = useMemo(() => {
    if (queryDefault || queryDefault.q) {
      return queryDefault.q.toUpperCase();
    }
    return null;
  }, [queryDefault]);

  const showDetail = (code) => {
    navigate(`/search/pihk/detail/${code}`);
  };
  const results = useMemo(() => {
    if(additionalSearchData) {
      return additionalSearchData?.Data ?? []
    }
    return [];
  }, [additionalSearchData])
  return (
    <Layout menu={menu} contact={contact} address={address} {...props}>
      <main className="flex-1">
        <div className="container my-10">
          <div className="flex flex-col items-start gap-10 md:flex-row">
            <div className="w-full md:w-full">
              <div className="text-3xl font-semibold text-gray-400">
                Hasil untuk <span className="text-fontPrimary">{query}</span>
              </div>
              <Content
                query={query}
                isShowMore={false}
                results={results}
                showDetail={showDetail}
              />
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

const Content = ({
  query,
  loading,
  results,
  error,
  showDetail,
}) => {
  if (!query) {
    return null;
  }
  if (loading && results.length === 0) return <CircleLoading />;

  if (!results || results.length === 0 || error) {
    return (
      <div className="text-center">
        <p className="mt-4 text-gray-400">
          {error
            ? "Maaf, telah terjadi kesalahan internal"
            : "Kami tidak menemukan hasil. Mohon coba kata kunci yang berbeda."}
        </p>
      </div>
    );
  }
  return (
    <div className="my-4 w-full relative overflow-x-auto">
      <div className="mt-3">
        <table className="w-full table-auto text-center">
          <thead className="border-b bg-neutral-800 font-medium text-white dark:border-neutral-500 dark:bg-neutral-900">
            <tr>
              <th className=" px-6 py-4">No</th>
              <th className=" px-6 py-4">Nama PIHK</th>
              <th className=" px-6 py-4">Status PIHK</th>
              <th className=" px-6 py-4">Kontak Alamat</th>
              <th className=" px-6 py-4">Action</th>
            </tr>
          </thead>
          <tbody>
            {results.map((data, index) => {
              return (
                <tr
                  className="border-b dark:border-neutral-500"
                  key={"table-pihk-" + index}
                >
                  <td className=" px-6 py-4">{index + 1}</td>
                  <td className=" px-6 py-4">{data.nama_pihk}</td>
                  <td className=" px-6 py-4">{data.status_pihk}</td>
                  <td className=" px-6 py-4">{data.alamat}</td>
                  <td className="min-w-350 py-4">
                    <Button
                      className="inline-flex min-w-full items-center"
                      onClick={() => showDetail(data.kode_pihk)}
                    >
                      <FaEye className="mr-2" /> Detail
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export async function getServerData({ query }) {
  const today = new Date();
  const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
  const isoThirtyDaysAgo = thirtyDaysAgo.toISOString().toString();
  
  try {
    const response = await fetch(process.env.GRAPHQL_URL_SSR, {
      cache: "no-cache",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: formatGQLQuery(
          [queryMenu, allCategories, popularArticlesQuery, contactAndAddress],
          [
            { key: "$popularArticlesLimit", type: "Int!" },
            { key: "$popularArticlesMaxDate", type: "DateTime!" },
          ]
        ),
        variables: {
          popularArticlesLimit: 5,
          popularArticlesMaxDate: isoThirtyDaysAgo,
        },
      }),
    });
    let additionalSearchData;
    if(query && query?.q) {
      const queryValue = query?.q;
      const url = process.env.SISKOHAT_URL + "/pihk/" + queryValue.toUpperCase();
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-key": process.env.XKEY_SISKOHAT,
        },
        agent: new (require('https').Agent)({ rejectUnauthorized: false }) // Bypass SSL verification
      }
      additionalSearchData = await fetch(url, requestOptions).then(res=> {
        if(res.ok) return res.json();
        return null;
      });
    }

    const { data } = await response.json();
    return {
      props: {
        menu: data?.menu?.data,
        categories: data?.allCategories?.data,
        popularArticles: data?.popularArticles?.data,
        query,
        contact: data?.contact?.data,
        address: data?.address?.data,
        additionalSearchData
      },
      status: 200,
    };
  } catch (error) {
    return {
      status: 500,
    };
  }
}
